import * as React from "react";
import { useState } from "react";
import { Header, Container, Segment, Icon, Form, FormProps, Message } from "semantic-ui-react";
import {withLayout} from "../components/Layout";

const ContactPage = () => {
  const [formData, setFormData] = useState({});
  const [success, setSuccess] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (event: (React.ChangeEvent | React.FormEvent)) => {
    const target = event.target as HTMLInputElement;
    setFormData({
      ...formData,
      [target.name]: target.value.trim(),
    });
  };

  const handleSubmitForm = (ev: React.FormEvent) => {
    setSubmitted(true);
    ev.preventDefault();
    const submitData = new URLSearchParams();
    const values = formData as any;
    Object.keys(values).forEach((key) => submitData.append(key, values[key]));
    const result = fetch(
      "https://formspree.io/f/xwkwbrpb",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: submitData,
      },
    );
    result.then((result) => {
      setSuccess(true);
    });
  };

  return (
    <Container text>
      <Segment vertical>
        <Header as="h2">
          <Icon name="bullhorn" />
          <Header.Content>
            Contact
          </Header.Content>
        </Header>
        <p>
          If you're interested in building the next economy we want to talk to you.
          Please reach out and share your thoughts.
        </p>
        <Form
          onSubmit={handleSubmitForm}
          success={success}
        >
          <Form.Input fluid onChange={handleChange} name="name" label="Name" placeholder="Name"></Form.Input>
          <Form.Input fluid onChange={handleChange} name="email" label="Contact Email" placeholder="Contact Email"></Form.Input>
          <Form.TextArea onChange={handleChange} name="message" label="Message" placeholder="Message" style={{minHeight: 200}} />
          <Form.Button disabled={submitted}>Submit</Form.Button>
          <Message
            success
            header="Message Received"
            content="We'll respond by email"
          />
        </Form>
      </Segment>
    </Container>
  );
};

export default withLayout(ContactPage);
